/* General styles for the header */
#header {
    position: fixed;
    width: 100%;
    z-index: 1100;
  }
  
  /* Bandera responsive */
  .bandera {
    width: 20px;
    height: 15px;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 600px) {
    .header-toolbar {
      flex-direction: column !important;
      text-align: center;
      gap: 1rem;
    }
  
    .header-icons {
      flex-direction: row;
      justify-content: center;
      gap: 1.5rem;
    }
  
    .header-logo {
      margin: 1rem 0;
    }
  }
  